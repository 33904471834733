
export default {
	name: "IndexPage",
	data() {
		return {
			currentStep: 0,
			progress: 0,
			topOffset: 80,
		};
	},
	computed: {
		steps() {
			return [
				{
					number: 1,
					title: this.$t("home.study-the-field-title"),
					desc: this.$t("home.study-the-field-desc"),
					sub: this.$t("about.study-the-field-sub"),
					to: this.localePath({ name: "educational" }),
					isAllow: true,
				},
				{
					number: 2,
					title: this.$t("home.practice-without-risks-title"),
					desc: this.$t("home.practice-without-risks-desc"),
					sub: this.$t("home.open-a-demo-account"),
					href: this.crmDomain("accounts/open-account/demo/"),
					isAllow: this.$store.state.currentEntity.configs.can_open_account_demo,
				},
				{
					number: 3,
					title: this.$t("home.start-real-trading-title"),
					desc: this.$t("home.start-real-trading-desc"),
					sub: this.$t("home.start-now"),
					href: this.crmDomain("accounts/open-account/individual/"),
					isAllow: this.$store.state.currentEntity.configs.can_open_account_individual,
				},
			];
		},
	},
	methods: {
		onIntersect(entries, observer) {
			if (entries[0].isIntersecting) {
				this.currentStep = Number(entries[0].target.dataset.step);
			}
		},
	},
};
