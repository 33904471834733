
export default {
	data() {
		return {};
	},
	computed: {
		points() {
			return [
				{
					title: this.$t("home.ingot-card-point-1"),
				},
				{
					title: this.$t("home.ingot-card-point-2"),
				},
				{
					title: this.$t("home.ingot-card-point-3"),
				},
				{
					title: this.$t("home.ingot-card-point-4"),
				},
				{
					title: this.$t("home.ingot-card-point-5"),
				},
			];
		},
	},
};
