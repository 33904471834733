
export default {
	components: {
		[process.client && "VTypical"]: () => import("vue-typical"),
	},
	methods: {
		timeupdate(time) {
			const cutTime = Math.floor(time.srcElement.duration);

			if (time.srcElement.currentTime >= cutTime) {
				this.$refs.intro.currentTime = 2.6;

				this.$refs.intro.play()?.catch((err) => {
					console.log(err);
				});
			}
		},
	},
};
