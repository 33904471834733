import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=d340b7ec&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DubaiBanner: require('/var/www/ingotbrokers-website/components/DubaiBanner.vue').default,Ambassador: require('/var/www/ingotbrokers-website/components/Ambassador.vue').default,Hero: require('/var/www/ingotbrokers-website/components/Hero.vue').default,WhoWeAre: require('/var/www/ingotbrokers-website/components/WhoWeAre.vue').default,RegistrationSteps: require('/var/www/ingotbrokers-website/components/RegistrationSteps.vue').default,IngotTrader: require('/var/www/ingotbrokers-website/components/ingotTrader.vue').default,ChooseYourMarket: require('/var/www/ingotbrokers-website/components/ChooseYourMarket.vue').default,WeKeepItSimple: require('/var/www/ingotbrokers-website/components/WeKeepItSimple.vue').default,IngotCard: require('/var/www/ingotbrokers-website/components/IngotCard.vue').default,CopyTrading: require('/var/www/ingotbrokers-website/components/CopyTrading.vue').default,Mam: require('/var/www/ingotbrokers-website/components/Mam.vue').default,AvailablePaymentMethods: require('/var/www/ingotbrokers-website/components/AvailablePaymentMethods.vue').default,PromoteTrading: require('/var/www/ingotbrokers-website/components/PromoteTrading.vue').default,WeHelpYou: require('/var/www/ingotbrokers-website/components/WeHelpYou.vue').default})
