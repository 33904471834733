
import meta from "~/mixins/meta";
export default {
	name: "IndexPage",
	mixins: [meta],
	data() {
		return {};
	},
	created() {
		this.setMetaTitle(this.$t("home.banner-title"));
	},
};
