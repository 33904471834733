import { render, staticRenderFns } from "./ingotTrader.vue?vue&type=template&id=7f9b7304&"
var script = {}
import style0 from "./ingotTrader.vue?vue&type=style&index=0&id=7f9b7304&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports