export default {
	data() {
		return {
			meta: {
				title: "",
				description: "",
				image: "",
			},
			defaultMeta: {
				title: "",
				description: "",
				image: "/images/og.jpg",
			},
		};
	},
	methods: {
		setMetaTitle(title) {
			if (title) this.meta.title = title;
		},
		setMetaDescription(description) {
			if (description) this.meta.description = description;
		},
		setMetaImage(image) {
			if (image) this.meta.image = this.$img(image, { width: 1200 }, { provider: "bunnyCDN", modifiers: { aspect_ratio: "1.91:1" } });
		},
		setMeta(title, description, image) {
			this.setMetaTitle(title);
			this.setMetaDescription(description);
			this.setMetaImage(image);
		},
	},
	head() {
		// if $fetchState.pending is true, then the page is still loading, show the title as animated dots
		// if $fetchState.error is true, then the page failed to load, show the title as "Error"
		// if $fetchState.pending is false and $fetchState.error is false, then the page is loaded, show the title as the page title

		let title = this.meta.title || this.defaultMeta.title;
		if (this.$fetchState) {
			if (this.$fetchState.pending) {
				title = "...";
			} else if (this.$fetchState.error) {
				title = "Error";
			}
		}
		return {
			titleTemplate: `%s - ${this.$store.state.currentEntity.translatable_configs.company_name}`,
			title,
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.meta.description || this.defaultMeta.description,
				},
				{
					hid: "og:title",
					property: "og:title",
					content: this.meta.title || this.defaultMeta.title,
				},
				{
					hid: "og:description",
					property: "og:description",
					content: this.meta.description || this.defaultMeta.description,
				},
				{
					hid: "og:image",
					property: "og:image",
					content: this.meta.image || this.defaultMeta.image,
				},
				{
					hid: "twitter:title",
					name: "twitter:title",
					content: this.meta.title || this.defaultMeta.title,
				},
				{
					hid: "twitter:description",
					name: "twitter:description",
					content: this.meta.description || this.defaultMeta.description,
				},
				{
					hid: "twitter:image",
					name: "twitter:image",
					content: this.meta.image || this.defaultMeta.image,
				},
			],
		};
	},
};
