
export default {
	props: {
		src: {
			type: String,
			required: true,
		},
		sm: {
			type: String,
			default: null,
		},
		md: {
			type: String,
			default: null,
		},
		lg: {
			type: String,
			default: null,
		},
		xl: {
			type: String,
			default: null,
		},
		muted: {
			type: Boolean,
			default: true,
		},
		autoplay: {
			type: Boolean,
			default: true,
		},
		loop: {
			type: Boolean,
			default: true,
		},
		autoPlayPause: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isMutedIntensionally: true,
		};
	},
	computed: {
		calculatedSrc() {
			// return the proper src size, the if the same not size not available return the nearest size, start from small to large
			if (this.$vuetify.breakpoint.smAndDown) {
				return this.sm || this.src;
			} else if (this.$vuetify.breakpoint.mdAndDown) {
				return this.md || this.sm || this.src;
			} else if (this.$vuetify.breakpoint.lgAndDown) {
				return this.lg || this.md || this.sm || this.src;
			} else if (this.$vuetify.breakpoint.xlAndDown) {
				return this.xl || this.lg || this.md || this.sm || this.src;
			} else {
				return this.xl || this.lg || this.md || this.sm || this.src;
			}
		},
	},

	methods: {
		onIntersect(entries, observer) {
			if (!this.autoPlayPause) return;
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const promise = this.$refs.video.play();
					promise
						.then(() => {
							// Autoplay started
							this.isMutedIntensionally = true;
							this.$refs.video.muted = true;
						})
						.catch(() => {
							// Autoplay was prevented.
							if (!this.$refs.video) return;

							this.$refs.video.muted = true;
							this.isMutedIntensionally = true;
							this.$refs.video.play()?.catch((err) => {
								console.log(err);
							});
						});
				} else {
					this.$refs.video.pause()?.catch((err) => {
						console.log(err);
					});
				}
			});
		},
		toggleMute() {
			this.$refs.video.muted = !this.$refs.video.muted;
			this.isMutedIntensionally = this.$refs.video.muted;
		},
	},
};
