
export default {
	data() {
		return {
			value: false,
		};
	},
	mounted() {
		// start loading video after 10 seconds
		setTimeout(() => {
			this.value = true;
		}, 10 * 1000);
	},
};
