
export default {
	data() {
		return {
			negativeMargin: 0,
		};
	},

	computed: {
		flatProducts() {
			const arr = [];
			this.$store.state.products?.forEach((item) => {
				if (item.children) {
					item.children.forEach((child) => {
						if (child.featured) {
							child.parent = item;
							arr.push(child);
						}
					});
				}
				if (item.featured) {
					arr.push(item);
				}
			});
			return arr;
		},
	},
	mounted() {
		this.negativeMargin = this.$refs.sticked.clientHeight * -1 + "px";
	},
};
