
export default {
	data() {
		return {
			serverData: [],
		};
	},

	async fetch() {
		await this.$apiOrRedis(`/v2/website/payment-gateways`)
			.then((resp) => {
				if (Array.isArray(resp)) {
					this.serverData = resp;
				}
			})
			.catch(this.pageErrorHandler);
	},
};
